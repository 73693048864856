import React, { Component } from 'react';
import HeadTags from '../components/global/headTags';
import InlineMailChimpForm from '../components/magazine/inlineMailChimpForm';
import FeaturedArticle from '../components/magazine/featuredArticle';
import SmallFeaturedArticle from '../components/magazine/smallFeaturedArticle';
import Promo from '../components/magazine/promo';
import SmallVerticleArticleSummary from '../components/magazine/smallVerticleArticleSummary';
import { graphql } from 'gatsby';
import MagazineLayout from '../layouts/magazine-layout';

class Archive extends Component {
	render() {
		const {
			title,
			meta_description,
			featured_image,
			featured_articles,
			paragraphs,
		} = this.props.data.allItemsJson.edges[0].node;
		const {
			articles,
		} = this.props.data;

		const mainFeaturedArticle = featured_articles[0];
		const otherFeaturedArticles = featured_articles.slice(1);
		const featuredIds = featured_articles.map((article) => article.id);
		const promos = paragraphs.filter(paragraph => paragraph.type === 'grid_text_callout');
		const mailChimpForms = paragraphs.filter(paragraph => paragraph.type === 'mailchimp_form');
		const introTexts = paragraphs.filter(paragraph => paragraph.type === 'textarea');
		const introText = (introTexts.length > 0) ? introTexts[0] : '';
		
		let displayArticles = articles.edges.filter((edge) => edge.node.article_type === "" || edge.node.article_type === "Blog").map((edge) => {
			var article = edge.node;
			article['image'] = article.featured_image.image;
			return article;
		}).filter((article) => !featuredIds.includes(article.id));
		
		return (
			<MagazineLayout {...this.props} excludeParentFunctions>
				<div className="archive-content-wrapper">
					<HeadTags
						title={title}
						description={meta_description}
						image={featured_image ? featured_image.image.sizes.hero_md : null}
						imageTwitter={
							featured_image ? featured_image.image.sizes.hero_sm : null
						}
						location={this.props.location}
					/>
					<div className="archive-main-content">
						<div className="main-features">
							<div className="opening-text"
								dangerouslySetInnerHTML={{
									__html: introText.text,
								}}>
							</div>
							<div className="featured-article">
								{mainFeaturedArticle &&
									<div className="feature">
										<FeaturedArticle article={mainFeaturedArticle} />
									</div>
								}
								<div className="main-feature-divider" />
							</div>
							<div className="other-features">
								{otherFeaturedArticles.map((article, i) => (
									<div key={`featured${i}`}>
										<SmallFeaturedArticle article={article} />
										{otherFeaturedArticles.length != (i + 1) &&
											<div className="mobile-divider" />
										}
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="mailchimp-wrapper">
						<InlineMailChimpForm {...this.props.data.social.edges[0].node} />
					</div>
					<div className="archive-main-content">
						<div className="article-list">
							{displayArticles.map((article, i) => (
								<div key={`article${i}`} className="small-article">
									<SmallVerticleArticleSummary article={article} />
									{(displayArticles.length != (i + 1) && article.authors) &&
										<div className="mobile-divider-uneven" />
									}
									{(displayArticles.length != (i + 1) && !article.authors) &&
										<div className="mobile-divider-large-text" />
									}
								</div>
							))}
						</div>
					</div>
					{promos.length > 0 &&
						<Promo promo={promos[0]} size="large" />
					}
				</div>
			</MagazineLayout>
		);
	}
}

export default Archive;

// eslint-disable-next-line
export const archivePageQuery = graphql`
query archivePageContentByPath(
	$path: String!
	$relatedChallengeId: String!
	$menuId: String!
	$isEs: Boolean!
	$idRegex: String!
	$blog: String!
	$translations: String!
  ) {
	allItemsJson(filter: { path: { eq: $path } }) {
	  edges {
		node {
		  path
		  meta_description
		  title
		  related_challenge {
			id
			title
			path
		  }
		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				original
			  }
			}
		  }
		  featured_articles {
			id
			title
			authors {
			  id
			  name
			}
			created_date
			short_description
			content_type
			category
			path
			image {
			  sizes {
				square_sm
				square_md
				near_square_lg
				near_square_md
				near_square_sm
				original
			  }
			  credit
			  alt_text
			  title
			}
			related_challenge {
			  id
			  title
			  path
			}
		  }
		  ...queryParagraphFields
		}
	  }
	}
	social: allItemsJson(filter: { jsonId: { eq: $menuId } }) {
	  edges {
		node {
		  menu_social {
			title
			menu_name
			weight
			url
			class
			target
		  }
		}
	  }
	}
	articles: allItemsJson(
	  filter: {
		related_challenge: { id: { eq: $relatedChallengeId } }
		content_type: { eq: "article" }
		published: { eq: true }
		jsonId: { regex: $idRegex }
		testing: {eq: false}
	  }
	  sort: { fields: [created_date], order: DESC }
	) {
	  edges {
		node {
		  id
		  title
		  created_date
		  short_description
		  meta_description
		  content_type
		  path
		  published
		  category
		  article_type
		  featured_image {
			image {
			  sizes {
				hero_lg
				hero_md
				hero_sm
				landscape_md
				landscape_sm
				near_square_lg
				near_square_md
				near_square_sm
				original
			  }
			  credit
			  alt_text
			  title
			}
		  }
		  related_challenge {
			id
			title
			path
		  }
		  authors {
			id
			name
		  }
		}
	  }
	}
  
	# MagazineLayout Data
	...headerFields @skip(if: $isEs)
	...footerFields @skip(if: $isEs)
	...headerFieldsEs @include(if: $isEs)
	...footerFieldsEs @include(if: $isEs)
	challenges: allItemsJson(
	  filter: {
		content_type: { regex: "/timeline|article|challenge/" }
		jsonId: { regex: $idRegex }
	  }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		  related_challenge {
			title
			path
		  }
		}
	  }
	}
	pages: allItemsJson(
	  filter: { content_type: { eq: "page" }, jsonId: { regex: $idRegex } }
	) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	blog: allItemsJson(filter: { jsonId: { eq: $blog } }) {
	  edges {
		node {
		  title
		  content_type
		  path
		}
	  }
	}
	translations: allItemsJson(filter: { jsonId: { eq: $translations } }) {
	  edges {
		node {
		  translations {
			id
			key
			value
		  }
		}
	  }
	}
	megaMenu: allItemsJson(filter: {content_type: {eq: "mega_menu"}}) {
		edges {
		  node {
			id
			jsonId
			content_type
			about {
			  link
			  title
			}
			our_team {
			  link
			  title
			}
			projects {
			  link
			  title
			}
			newsletter {
			  link
			  title
			}
			latest_stories {
			  uri
			  title
			}
			magazine {
			  link
			  title
			}
			educational_resources {
			  uri
			  title
			  image_url
			  image_alt
			}
			topics {
				display
				link
				langcode
			}
			translated {
			  stories_and_resources
			  educational_resources
			  latest_stories
			  magazine
			}
			lang
		  }
		}
	}
  }  
`;